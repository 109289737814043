import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import 'dexie-syncable';
// import './friz.otf';
import './fonts/friz.otf';
import NotificationContextWrapper from './hooks/toast/notificationContext';
import ToastStack from './components/toast/toastStack';

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotificationContextWrapper>
      <App />
      <ToastStack />
    </NotificationContextWrapper>
  </React.StrictMode>
);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
