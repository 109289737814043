import { createContext } from 'react';
import { IUseNotifyReturns, useNotify } from './useToastNotify';

export const NotificationContext = createContext<Partial<IUseNotifyReturns>>({});

const NotificationContextWrapper = ({ children }) => {
  const notificationContextValue = useNotify()
  return (
    <NotificationContext.Provider value={notificationContextValue}>
      { children }
    </NotificationContext.Provider>
  )
}
export default NotificationContextWrapper;