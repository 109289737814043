import { useCallback } from "react"
import * as yup from 'yup';

// Yup validation hook from docs
export const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );

export const formFieldValidations = yup.object().shape({
  userFName: yup.string().required('Please enter your first name.'),
  userEmail: yup.string().email('The email you entered is not valid.').required('Please enter your email.'),
  userShopName: yup.string().required('Please enter your shop or company name.')
})