import * as React from 'react';
import './App.css';
import { MdSignalWifiConnectedNoInternet0 } from 'react-icons/md';
import { ImConnection } from 'react-icons/im';
import { formInputs } from './files/formInputs';
import { useAppHooks } from './hooks/useApp';
import { Helmet } from 'react-helmet';
import Video from './components/video';
import PoweredBy from './components/poweredBy/poweredBy';

function App() {
	const {
		networkConnected,
		register,
		handleSignup,
		handleSubmit,
		errors,
		submitted,
		submitting,
    setSubmitted
	} = useAppHooks();
  
  const [showTimer, setShowTimer] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(10);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    if (!window.location.href.includes('.co.uk')) {
      window.location.assign('http://rallywithcanna.co.uk')
    }
  }, [])


  React.useEffect(() => {
    if (!submitted) {
      setTimeLeft(10)
      return;
    };
    setShowTimer(true);
  }, [submitted])

  React.useEffect(() => {
    if (!showTimer) return;
    const timerInterval = setInterval(() => {
      console.log('time left:', timeLeft)
      if (timeLeft <= 0) {
        setShowTimer(false);
        setSubmitted(false);
      };
      setTimeLeft(timeLeft - 1)
    }, 1000);

    return () => {
      clearInterval(timerInterval)
    }
  }, [showTimer, timeLeft])

	return (
		<>
      <Helmet>
        <meta name='viewport' content='initial-scale=1.0, maximum-scale=1' /> 
				<link rel='icon' type='image/ico' href='/canna-favicon.ico'/>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true' />
        <link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet" />
        {/* <link rel='stylesheet' href="/" */}
      </Helmet>
      <main className='App'>
        <div className='content'>
          <div className='logo_container'>
            <img
              src='/canna-logo.png'
              width='126px'
              height='auto'
              alt='CANNA Logo'
            />
          </div>

          <h1>
            Do you want to experience a <br /> CANNA rally?
          </h1>
          <Video />

          <section className='form_section'>
            {submitted ? (
              <>
                <h2>Your entry has been received</h2>
                <br />
                <p>Now go grab a drink or roll a j and get back to the event.</p>
                <div style={{ height: '4px', width: `${timeLeft*10}%`, background: 'white', marginTop: '24px', transition: 'width 1s linear' }} />
              </>
            ) : (
              <>
                <h2>
                  Fill out the form to <br /> register your interest
                </h2>
                <form noValidate onSubmit={handleSubmit(handleSignup)} autoComplete='off'>
                  {formInputs.map(({ label, fieldId, placeholder, type }) => (
                    <FormInput
                      key={`form_input_${label}`}
                      register={register}
                      fieldId={fieldId}
                      label={label}
                      placeholder={placeholder}
                      type={type}
                      errors={errors}
                    />
                  ))}
                  <button type='submit' disabled={submitting}>
                    {submitting ? 'Submitting..' : 'Submit'}
                  </button>
                </form>
              </>
            )}
          </section>
        </div>

        <div className='built_by'>
          <PoweredBy />
        </div>
        <div className='connection_status'>
          {!networkConnected ? (
            <MdSignalWifiConnectedNoInternet0/>
          ) : (
            <ImConnection/>
          )}
        </div>
      </main>
    </>
	);
}

const FormInput = ({ register, fieldId, label, placeholder, type, errors }) => {
	return (
		<label>
			<span>{label}</span>
			<input {...register(fieldId)} placeholder={placeholder} type={type} autoComplete='off'/>
			{errors &&
				Object.keys(errors).length > 0 &&
				errors[fieldId] &&
				errors[fieldId].message && (
					<span className='error_message'>{errors[fieldId].message}</span>
				)}
		</label>
	);
};

export default App;
