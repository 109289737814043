import * as React from 'react';

const PoweredBy = () => {
  return (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center', background: '#fba220', width: 'fit-content', padding: '2px', borderRadius: '24px' }}>
      <img src='/POWEREDBY.png' width='24px' height='24px' />
      <p style={{ paddingRight: '16px', color: 'black' }}>Powered by NPK Media</p>
    </div>
  )
}
export default PoweredBy;