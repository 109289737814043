export const formInputs = [
  {
    label: 'Enter your first name',
    fieldId: 'userFName',
    placeholder: 'Your first name',
    type: 'text'
  },
  {
    label: 'Enter your email',
    fieldId: 'userEmail',
    placeholder: 'Your email',
    type: 'email'
  },
  {
    label: 'Enter your shop or company name',
    fieldId: 'userShopName',
    placeholder: 'Your shop or company name',
    type: 'text'
  }
]