import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react"
import Dexie from "dexie";
import { FieldValues, useForm, UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { formFieldValidations, useYupValidationResolver } from "./resolver";
import { NotificationContext } from "./toast/notificationContext";
import { useNetworkMonitor } from "./useNetworkMonitor";

export const cannaApi = async (method, path, body, headers) => {
  const fetchResponse = fetch(`https://api.rallywithcanna.com/${path ?? ''}`, {
    method: method ?? 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(body)
  })
  .then(async(Response) => {
    const json = await Response.json();
    return json;
  })
  .catch(err => {
    console.log('Fetch error', err);
    return null;
  })
  return fetchResponse
}

type IUseAppHooksReturns = {
  networkConnected: boolean;
  handleSignup: () => void;

  // form logic
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: {[x: string]: any};
  submitted: boolean;
  submitting: boolean;
  setSubmitted: Dispatch<SetStateAction<boolean>>
}

export const useAppHooks = (): IUseAppHooksReturns => {
  const { popupToast } = useContext(NotificationContext);

  const { networkConnected } = useNetworkMonitor();

  // --- Local db and submissions --- 
  const dbRef = useRef(null);
  const backupDbRef = useRef(null);
  
  // Initialize main db and backup db
  useEffect(() => {
    const localDb = new Dexie('CannaLocalSignupsV1');   
    localDb.version(1).stores(
      { items: 'id++, userEmail, localSignupTime, userFName, userShopName' }
    );
    dbRef.current = localDb;

    const backupDb = new Dexie('CannaSignupsBackup');
    backupDb.version(1).stores(
      { items: 'id++, userEmail, localSignupTime, userFName, userShopName'}
    );
    backupDbRef.current = backupDb;
  }, []);


  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { handleSubmit, register, watch, formState: { errors }, reset } = useForm({
    mode: 'onChange',
    resolver: useYupValidationResolver(formFieldValidations)
  });

  const handleSignup = async () => {
    setSubmitting(true);

    const signupTime = new Date().toUTCString();

    const { userFName, userEmail, userShopName } = watch();

    const signupItem = {
      localSignupTime: signupTime,
      userEmail: userEmail, 
      userFName: userFName,
      userShopName: userShopName
    }

    if (networkConnected) {
      // post to server and add straight to mongo
      try {
        console.log('attempting submit to api')
        const data: any = await cannaApi(
          'POST', 
          'postUserEntry', 
          {        
            isMultiple: false, 
            entries: [signupItem]
          }, 
          null
        );

        if (!data) {
          popupToast('Something went wrong', 'error');
          throw new Error('')
        }

        if (data && data.resData.errorMsg && data.resData.errorMsg !== '') {
          popupToast('That email has already been registered', 'error');
          reset();
          setSubmitting(false);
          return;
        }

        setSubmitting(false);
        setSubmitted(true);
        reset()
      } catch(err) {
        setSubmitting(false);
      }

    } else {
      // no network connection > store in local indexed db
      await dbRef.current.items.add(signupItem);
      setSubmitted(true);
      setSubmitting(false);
      reset()
    }
  }

  useEffect(() => {
    if (!networkConnected) return;
    console.log('Network state changed to up, checking to post any local signups to server');
    handleSubmitOnNetworkOpen();
  }, [networkConnected]);


  // Clone items into backup table and clear main
  const clearItems = async () => {
    const allCurrentItems = await dbRef.current.items.toArray();
    await backupDbRef.current.items.bulkAdd(allCurrentItems);
    await dbRef.current.items.clear();
  }

  const handleSubmitOnNetworkOpen = async () => {
    try {
      const items = await dbRef.current.items.toArray();

      // Check if items in local indexed db to send
      if (items && items.length > 0) {
        console.log('Got items in local to send to server..');

        const itemsToPost = items.map(({ signupTime, userEmail, userFName, userShopName }) => {
          return {
            localSignupTime: signupTime,
            userEmail: userEmail, 
            userFName: userFName,
            userShopName: userShopName
          }
        });
  
        const data: any = await cannaApi(
          'POST', 
          'postUserEntry', 
          {
            isMultiple: true,
            entries: itemsToPost,
          }, 
          null
        );
  
        if (!data) {
          console.log('Nothing returned of posting locals to api. Keeping store for next test');
          return;
        }

        clearItems()
      } else {
        console.log('No local items to sync to server')
      }

    } catch(err) {
      console.log('Failed to local items to server.');
    };
  }

  return {
		networkConnected,
		register,
		handleSignup,
		handleSubmit,
		errors,
		submitted,
		submitting,
    setSubmitted
  }
}