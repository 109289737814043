import * as React from 'react';
import style from './style.module.scss';
// import { ImSpinner9 } from 'react-icons/im';
// import storyblok from '../config';
import { ImSpinner9 } from 'react-icons/im';
import Dexie from 'dexie';

const tenEighty = 'https://a.storyblok.com/f/169739/x/a60ff32655/video-1080.mp4'
const small = "https://a.storyblok.com/f/169739/x/09bec13c3c/pexels-serkan-bayraktar-8214649.mp4"

const VideoHeight = 2160;
const VideoWidth = 3840;
const videoAspect = VideoWidth/VideoHeight

const vimeoEmbedCode = 'bwyy4sXo93o';

const Video = () => {
  const [videoSrc, setVideoSrc] = React.useState<string | null>(null);
  const [videoLoaded, setVideoLoaded] = React.useState<boolean>(false);
  const [videoError, setVideoError] = React.useState<boolean>(false);

  const [maxWidth, setMaxWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  const videoDbRef = React.useRef(null);

  React.useEffect(() => {
    if (typeof window === undefined) return;
    handleWidth();
    window.addEventListener('resize', handleWidth);
    return () => window.removeEventListener('resize', handleWidth);
  }, []);

  const handleWidth = () => {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    const max = (screenHeight - 16) * videoAspect;
    const height = (Math.min(max, screenWidth) - 16) * (VideoHeight/VideoWidth);
    setMaxWidth(max);
    setHeight(height);
  };


  const videoRef = React.useRef(null);

  async function preloadVideo(src) {
    const res = await fetch(src);
    const blob = await res.blob();
    videoDbRef.current.items.add({
      videoSrc: blob
    });
    return URL.createObjectURL(blob);
  }

  
  React.useEffect(() => {
    if (videoSrc) return;
    const videoDb = new Dexie('CannaRallyVids');   
    videoDb.version(1).stores(
      { items: 'id++, videoSrc' }
    );
    videoDbRef.current = videoDb;

    handleFetchVideo()
  }, []);

  const handleFetchVideo = async () => {
    const items = await videoDbRef.current.items.toArray();

    // Get from indexed db if available
    if (items.length > 0) {
      const itemOne = items[0].videoSrc;
      const cachedVersion = URL.createObjectURL(itemOne);
      setVideoLoaded(true);
      videoRef.current.src = cachedVersion;
      videoRef.current.play();
      
      // else preload and store the video
    } else {
      const src = await preloadVideo(tenEighty);
      setVideoLoaded(true);
      videoRef.current.src = src;
      videoRef.current.play();
    }
  }



  return (
    <div 
      style={{
        position: 'relative',
        width: 'calc(100vw - 16px)',
        maxWidth: `${maxWidth}px`,
        height: `${height}px`,
        background: 'grey',
        borderRadius: '16px',
        margin: '80px auto',
        overflow: 'hidden'
      }}
    >                
      <video
        autoPlay
        preload='auto'
        muted
        loop
        playsInline
        ref={videoRef}
        // onLoadedData={() => setVideoLoaded(true)}
        style={{
          margin: 'auto',
          borderRadius: '16px',
          position: 'absolute',
          inset: 0,
          width: '100%',
          height: 'auto',
          
          // display: 'none',
          zIndex: 2
        }}>
        <source src='' type='video/mp4' />
        {/* <source src='https://media.githubusercontent.com/media/npk-media-dev/canna-signups/blob/master/web/public/video-1080.mp4' type='video/mp4' /> */}
      </video>


      {/* <div className={style.disabling_overlay} /> */}
      {!videoLoaded && (
        <div className={style.video_loading}>
          <div className={style.inner}>
            <div className={style.content}>
              <h1>Downloading video for offline use..</h1>
              <br />
              <p>This make take a minute..</p>
              <br />
              <ImSpinner9 color='white'/>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Video;