import { useContext, useEffect, useMemo, useState } from 'react';
import { NotificationContext } from '../../../hooks/toast/notificationContext';
import style from './toast.module.scss';

const ToastNotification = ({ msg, type, toastID }) => {
	const { removeToastPieceFromDom } = useContext(NotificationContext);
	const [remove, setRemove] = useState(false);
	const TIME_AFTER_FADE_REMOVE_FROM_DOM = 5300;

	useEffect(() => {
		if (remove) {
			removeToastPieceFromDom(toastID);
		}
	}, [remove]);

	// Remove from dom after x seconds after fade out
	// timeout should include the animation time and initial delay in style.fade_out
	useEffect(() => {
		setTimeout(() => {
			setRemove(true);
		}, TIME_AFTER_FADE_REMOVE_FROM_DOM);
	}, []);

	const toastStyles = useMemo(
		() => [style.wrap, style.fade_out].join(' '),
		[type]
	);

	return (
		<div className={toastStyles}>
			<div className={`${style.inner} ${style[type]}`}>
				<p>{msg}</p>
				<div
					className={style.close_container}
					onClick={() => removeToastPieceFromDom(toastID)}>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M4.22676 4.22676C4.5291 3.92441 5.01929 3.92441 5.32163 4.22676L12 10.9051L18.6784 4.22676C18.9807 3.92441 19.4709 3.92441 19.7732 4.22676C20.0756 4.5291 20.0756 5.01929 19.7732 5.32163L13.0949 12L19.7732 18.6784C20.0756 18.9807 20.0756 19.4709 19.7732 19.7732C19.4709 20.0756 18.9807 20.0756 18.6784 19.7732L12 13.0949L5.32163 19.7732C5.01929 20.0756 4.5291 20.0756 4.22676 19.7732C3.92441 19.4709 3.92441 18.9807 4.22676 18.6784L10.9051 12L4.22676 5.32163C3.92441 5.01929 3.92441 4.5291 4.22676 4.22676Z'
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};
export default ToastNotification;
