import { useContext } from 'react';
import { NotificationContext } from '../../hooks/toast/notificationContext';
import ToastItem from './notificationBox/toastNotification';
import style from './toast_stack.module.scss';

const ToastStack = () => {
  const { toasts } = useContext(NotificationContext);

  if(toasts.length > 0) {
    return (
      <div className={style.wrap}>
        <div className={style.inner}>
          {toasts && toasts.length > 0 && toasts.map(({ msg, type, toastID }) => (
            <ToastItem key={toastID} msg={msg} type={type} toastID={toastID}/>
          ))}
        </div>
      </div>
    )
  } else return null;

}
export default ToastStack;
