import { useState, useEffect } from "react";

/**
 * --- Network connection ---
 * Detects if network connected by pining current window origin 
 * Signup form will send straight to api if connected, otherwise 
 * stores data in indexed db and will send all once connection is 
 * restored.
 * @returns networkConnected boolean value
 */

interface IUseNetworkMonitorReturns {
  networkConnected: boolean;
}

export const useNetworkMonitor = (): IUseNetworkMonitorReturns => {
  const [networkConnected, setNetworkConnected] = useState(false);

  // check network on mount
  useEffect(() => {
    checkStatus()
  }, [])

  // Check network every 20 seconds
  useEffect(() => {
    const pingInterval = setInterval(() => {
      checkStatus();
    }, 20000);
    return () => clearInterval(pingInterval);
  }, []);

  // Async function to request head from current origin
  const checkStatus = async () => {
    try {
      const online = await isOnline();
      console.log('is online:', online)
      if (online) {
        setNetworkConnected(true);
      } else { 
        setNetworkConnected(false);
      }
    } catch(err) {
      console.log('network disconnected');
      setNetworkConnected(false);
    }
  }

  const getRandomString = () => {
    return Math.random().toString(36).substring(2, 15)
  }
  
  async function isOnline () {
    if (!window.navigator.onLine) return false
  
    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin)
  
    // random value to prevent cached responses
    url.searchParams.set('rand', getRandomString())
  
    try {
      const response = await fetch(
        url.toString(),
        { method: 'HEAD' },
      )
  
      return response.ok
    } catch {
      return false
    }
  }

  return {
    networkConnected
  }
}