import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface INotificationContent {
  msg: string
  type: 'error' | 'success' | 'info'
  toastID: string
}

export interface IUseNotifyReturns {
  toasts: Array<INotificationContent>
  popupToast: (msg: string, type: 'error' | 'success' | 'info') => void
  removeToastPieceFromDom: (toastID: string) => void
}

export const useNotify = ():IUseNotifyReturns => {
  const [toasts, setToasts] = useState([]);

  const popupToast = (msg:string, type: 'error' | 'success' | 'info') => {
    const toastID = uuidv4();
    setToasts(prevToasts => 
      [
        ...prevToasts, 
        { 
          msg: msg, 
          type: type, 
          toastID: toastID
        }
      ]
    );
  }

  const removeToastPieceFromDom = useCallback((toastID) => {
    setToasts(toasts.filter(rem => rem.toastID !== toastID))
  }, [toasts])

  return {
    toasts,
    popupToast,
    removeToastPieceFromDom
  }
}